// export default [
//   {
//     header: "My Pages",
//   },
//   {
//     title: "Services",
//     route: "apps-services",
//     icon: "ToolIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Events",
//     route: "apps-events",
//     icon: "CalendarIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Blog",
//     route: "apps-blog",
//     icon: "LayoutIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Tags",
//     route: "apps-tags",
//     icon: "TagIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Categories",
//     route: "apps-categories",
//     icon: "GridIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Promotions",
//     route: "apps-promotions",
//     icon: "PercentIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "States",
//     route: "apps-states",
//     icon: "MapPinIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Users",
//     route: "apps-users",
//     icon: "UserIcon",
//     acl: {
//       action: "read",
//       resource: "ACL",
//     },
//   },
//   {
//     title: "Service Merchant",
//     route: "merchant-control",
//     icon: "ToolIcon",
//   },
// ];

export default [
  // {
  //   header: 'My Pages',
  // },
  {
    title: 'Services',
    route: 'apps-services',
    icon: 'ToolIcon',
  },
  {
    title: 'Events',
    route: 'apps-events',
    icon: 'CalendarIcon',
  },
  {
    title: 'Blog',
    route: 'apps-blog',
    icon: 'LayoutIcon',
  },
  {
    title: 'Tags',
    route: 'apps-tags',
    icon: 'TagIcon',
  },
  {
    title: 'Categories',
    route: 'apps-categories',
    icon: 'GridIcon',
  },
  {
    title: 'Promotions',
    route: 'apps-promotions',
    icon: 'PercentIcon',
  },
  {
    title: 'States',
    route: 'apps-states',
    icon: 'MapPinIcon',
  },
  {
    title: 'Users',
    route: 'apps-users',
    icon: 'UserIcon',
  },
  {
    title: 'Service Merchant',
    route: 'merchant-control',
    icon: 'ToolIcon',
    action: 'read',
    // acl: {
    //   action: 'read',
    //   resource: 'ACL',
    // },
  },
]
